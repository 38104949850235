[data-theme='dark'] {
  --tabs-container-background-color: #141414;
}

[data-theme='light'] {
  --tabs-container-background-color: white;
}

.tabsContainer {
  background: var(--tabs-container-background-color);
  padding: 24px;
  width: 100%;
  border-radius: 0 6px 6px 6px;
}
