[data-theme='dark'] {
  --contracts-container-background-color: rgba(255, 255, 255, 0.04);;
}

[data-theme='light'] {
  --contracts-container-background-color: rgba(240, 246, 255, 1);
}

.formContainer {
  background: var(--contracts-container-background-color);
  border-radius: 6px;
  padding: 15px;
}

.formLabel {
  margin: 0 !important;
}